import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { DASHBOARD } from "../../constants/pathNames";
import logo from "../../assets/icons/logo_big.png";
import { colors } from "../../constants/styles";
import styled, { css } from "styled-components";
import { Grid, CircularProgress } from "@mui/material";
import { useAuthState, useAuthDispatch } from "../../providers/authProvider";
import useAPIError from "../../providers/hooks/useAPIError";
import ModalComponent from "../../components/ModalComponent";
import { Section } from "../../common/Layouts";
import CommonModal from "../../components/CommonModal";
import { AuthService } from "../../api/services";

const LogoSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 4% 0px 5% 0px;
  img {
    height: 250px;
    width: auto;
    @media (min-width: 1440px) {
      height: 300px;
    }
    @media (min-width: 1920px) {
      height: 420px;
    }
  }
`;
const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const TextFieldComponent = styled.input`
  border: 1px solid ${colors.lightBlue};
  border-radius: 45px;
  outline: none;
  padding: 13px 20px;
  font-size: 1.2vw;
  color: ${colors.lightBlue};
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  ${(props) =>
    props.last &&
    css`
      margin-bottom: 30px;
    `}
  ::placeholder {
    opacity: 0.46;
    font-size: 1.2vw;
    @media (min-width: 1920px) {
      //font-size: 25px;
    }
  }
  @media (min-width: 1920px) {
    //height: 74px;
    width: 570px;
    padding: 21px 42px;
  }
`;

const ButtonSubmit = styled.button`
  border: none;
  border-radius: 36px;
  color: ${colors.white};
  background-color: ${colors.lightBlue};
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2vw;
  width: 140px;
  height: 50px;

  @media (min-width: 1920px) {
    width: 177px;
    height: 72px;
    //font-size: 1.2vw;
  }
`;

const ModalContent = styled.div`
  padding: 34px 25px;
  box-sizing: border-box;
  span {
    &.title {
      font-size: 30px;
      color: ${colors.blue};
      text-align: left;
      font-family: "Avenir Next Bold";
      @media (min-width: 1920px) {
        font-size: 40px;
      }
    }
  }
`;

const WrapForm = styled.div`
  position: fixed;
  top: 35%;
  width: 100%;
`;

const TitleSection = styled.div`
  font-size: 1.7vw;
  font-weight: bold;
  font-family: "Avenir Next Bold";
  text-align: center;
  span {
    font-weight: normal;
    margin-left: 10px;
    @media (min-width: 1920px) {
      margin-left: 14px;
    }
  }
  @media (min-width: 1920px) {
    font-size: 30px;
  }
  h4 {
  }
`;

function ResetPassword() {
  const { addError } = useAPIError();
  const { password, setPassword } = useState();
  const [loading, setLoading] = useState(false);
  const [openResetLinkModal, setOpenResetLinkModal] = useState(false);

  var splitURL = window.location.href.toString().split("/");
  //3,4
  const email = splitURL[4];
  const token = splitURL[5];

  /*const classes = useStyles(); */
  let history = useHistory();

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character"),
    password_confirmation: Yup.string()
      .required("Password confirmation is required")
      .oneOf([Yup.ref('password'), null], "Passwords must match"),
  });

  const initialValues = {
    password: "",
    password_confirmation: "",
  };

  const handleSubmitResetPassword = (password, password_confirmation) => {
    setLoading(true);
  
    AuthService.resetPassword(email, password, password_confirmation, token).then((res) => {
      setLoading(false);
  
      if (res.status !== "success") {
        if (res.data && res.data.error) {
          const errorMessages = res.data.error;
          let formattedErrors = "There were some errors with your submission:\n\n";
  
          for (const [field, messages] of Object.entries(errorMessages)) {
            formattedErrors += `${field.charAt(0).toUpperCase() + field.slice(1)}:\n`;
            messages.forEach((message) => {
              formattedErrors += `- ${message}\n`;
            });
            formattedErrors += `\n`;
          }
  
          addError(formattedErrors || "Password update error occurred");
        } else {
          addError("Password update error occurred");
        }
      } else {
        setOpenResetLinkModal(true);
        setTimeout(() => history.push('/'), 3000);
      }
    }).catch((error) => {
      setLoading(false);
      addError("An unexpected error occurred");
    });
  };
  
  

  return (
    <div>
      <WrapForm>
        <TitleSection>Reset Password</TitleSection>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {}}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setValues,
            setTouched,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();

                handleSubmitResetPassword(values.password, values.password_confirmation);
              }}
            >
              <Grid container justifyContent="center">
                <Grid item md={3}>
                  <WrapperForm>
                    <TextFieldComponent
                      name="password"
                      type="password"
                      onChange={handleChange}
                      value={values.password}
                      error={errors.password}
                      placeholder="Password"
                    />
                    {errors.password && (
                      <label style={{ color: "red", textAlign: "left" }}>
                        {errors.password}
                      </label>
                    )}

                    <TextFieldComponent
                      name="password_confirmation"
                      type="password"
                      onChange={handleChange}
                      error={errors.password_confirmation}
                      value={values.password_confirmation}
                      placeholder="Confirm Password"
                    />
                    {errors.password_confirmation && (
                      <label style={{ color: "red" }}>
                        {errors.password_confirmation}
                      </label>
                    )}

                    <ButtonSubmit type="submit">
                      {loading ? (
                        <CircularProgress size={28} style={{ color: "#fff" }} />
                      ) : (
                        "Submit"
                      )}
                    </ButtonSubmit>
                  </WrapperForm>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </WrapForm>
      <CommonModal open={openResetLinkModal} show={openResetLinkModal}>
        <ModalContent>
          <Section>Your Password was changed successfully</Section>
        </ModalContent>
      </CommonModal>
    </div>
  );
}

export default ResetPassword;
